import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { processor } from '@seafile/seafile-editor/dist/utils/seafile-markdown2html';
import Loading from '../loading';
import { seatableMarketInternalAPI } from '../../utils/internal-api';
import { gettext } from '../../utils/constants';
import { Utils } from '../../utils/utils';
import toaster from '../toast';
import VideoPlayer from '../video-player';

const propTypes = {
  type: PropTypes.string.isRequired,
  card: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
  options: PropTypes.object,
};

class CardDetailDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      description: null
    };
  }

  componentDidMount() {
    let {card} = this.props;
    processor.process(card.description).then((result) => {
      let description = String(result);
      this.setState({
        isLoading: false,
        description: description
      });
    });
  }

  toggle = () => {
    this.props.toggle();
  }

  downloadPlugin = () => {
    seatableMarketInternalAPI.getPluginDownloadLink(this.props.card.zip_asset_path).then(res => {
      location.href = res.data.download_link;
    }).catch(error => {
      let errMessage = Utils.getErrorMsg(error);
      toaster.danger(errMessage);
    });
  }

  onExternalLinkClick = () => {
    let {card} = this.props;
    window.open(card.link);
  }

  changeImageURL = (innerValue) => {
    const {card} = this.props;
    if (innerValue.type === 'image') {
      let imageUrl = innerValue.data.src;

      // get image path
      let index = imageUrl.indexOf('/file');
      let index2 = imageUrl.indexOf('?');
      const imagePath = imageUrl.substring(index + 5, index2);
      // change image url
      innerValue.data.src = card.markdown_link + '/asset' + imagePath;
    }
    return innerValue;
  }

  modifyValueBeforeRender = (value) => {
    let newValue = Utils.changeMarkdownValue(value, this.changeImageURL);
    return newValue;
  }

  getHeaderTitle = (type) => {
    let headerTitle;
    switch (type) {
      case 'plugin':
        headerTitle = gettext('Plugin');
        break;
      case 'template':
        headerTitle = gettext('Template');
        break;
      case 'case':
        headerTitle = gettext('Case');
        break;
      case 'video':
        headerTitle = gettext('Video');
        break;
      default:
        break;
    }

    return headerTitle;
  }

  render() {
    let {type, card} = this.props;
    let {isLoading, description} = this.state;
    let headerTitle = this.getHeaderTitle(type);
    let videoItem =
      <Fragment>
        <div className={`${type}-card-detail-image-container`}>
          <VideoPlayer {...this.props.options} />
        </div>
        <div className="card-detail-info flex-wrap">
          <h4 className="card-detail-name m-0">{card.display_name}</h4>
        </div>
        <div className="card-detail-instruction" dangerouslySetInnerHTML={{__html: description}}></div>
      </Fragment>;
        
    let templateItem =
      <Fragment>
        <div className={`${type}-card-detail-image-container`}>
          {(type === 'template' && card.card_image_expanded_url) ?
            <img src={card.card_image_expanded_url} alt={card.display_name} className={`${type}-card-detail-image`}/> :
            <img src={card.card_image_url} alt={card.display_name} className={`${type}-card-detail-image`}/>
          }
          {type === 'plugin' && card.screenshot_url_list.map((url, index) => {
            return (
              <img src={url} key={`plugin-screenshot-${index}`} alt="" className={`${type}-card-detail-image ml-2`}/>
            );
          })}
        </div>
        {type !== 'case' &&
        <div className="card-detail-info flex-wrap">
          <h4 className="card-detail-name m-0">{card.display_name}</h4>
          <div>
            {card.zip_asset_path && card.zip_asset_path.length > 0 &&
            <Button color='green' className="card-detail-look mr-2"
              onClick={this.downloadPlugin}>{gettext('Download')}</Button>
            }
            {card.link &&
            <Button color='green' className="card-detail-look"
              onClick={this.onExternalLinkClick}>{gettext('View')}</Button>
            }
          </div>
        </div>
        }
        {type !== 'case' &&
        <div className="card-detail-instruction" dangerouslySetInnerHTML={{__html: description}}></div>
        }
      </Fragment>;

    return (
      <Modal isOpen={true} className="card-detail-dialog" toggle={this.toggle}
        contentClassName={'card-detail-container'}>
        <ModalHeader toggle={this.toggle}>{headerTitle}</ModalHeader>
        <ModalBody className="card-detail-content">
          {isLoading && <Loading/>}
          {!isLoading && (
            type === 'video' ? videoItem : templateItem
          )}
        </ModalBody>
      </Modal>
    );
  }
}

CardDetailDialog.propTypes = propTypes;

export default CardDetailDialog;
