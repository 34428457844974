import React from 'react';
import { Router } from '@reach/router';
import PluginModule from './market-plugins/plugin-module';
import TemplateModule from './market-templete/template-module';
// import CasesModule from './market-case/case-module';
import { siteRoot } from '../utils/constants';
import VideoModule from './market-video/video-module';

class MainPanel extends React.Component {

  render() {
    return(
      <div id="content" className="plugin-market-content">
        <Router className="reach-router">
          <TemplateModule path={siteRoot} />
          <PluginModule path={siteRoot + 'plugins/'} />
          <TemplateModule path={siteRoot + 'templates/'} />
          <VideoModule path={siteRoot + 'videos/'} />
          {/* <CasesModule path={siteRoot + 'cases/'} /> */}
        </Router>
      </div>
    );
  }
}

export default MainPanel;
