import React from 'react';
import PropTypes from 'prop-types';
import PluginItem from './plugin-item';

const propTypes = {
  pluginList: PropTypes.array,
  searchParams: PropTypes.object,
};

class PluginList extends React.Component {

  render() {
    let { pluginList } = this.props;
    
    return (
      <div className="plugin-list-container">
        {pluginList.map((plugin, index) => {
          return (<PluginItem key={index} plugin={plugin} searchParams={this.props.searchParams} />);
        })}
      </div>
    );
  }
}

PluginList.propTypes = propTypes;

export default PluginList;
