import React from 'react';
import EmptyTip from '../empty-tip';
import VideoList from './video-list';
import { seatableMarketInternalAPI } from '../../utils/internal-api';
import Video from '../../model/video';
import { Utils } from '../../utils/utils';
import toaster from '../toast';
import Loading from '../loading';
import MediaQuery from 'react-responsive';
import { gettext } from '../../utils/constants';

const ALL = gettext('All');

class VideoModule extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      category: ALL,
      isLoading: true,
      isVideoLoaded: false,
      isExistVideo: false,
      videoMap: {},
      videoCategories: [ALL],
      searchParams: null
    };
  }

  componentDidMount() {
    this.loadTemplateList();
    this.onHandleUrlSearch();
  }

  onHandleUrlSearch = () => {
    let { search } = location;
    if (!search) return;
    let searchParams = Utils.getUrlSearches(search);
    this.setState({
      searchParams: searchParams
    });
  }

  onCategoryClick = (category) => {
    this.setState({category: category});
  }

  loadTemplateList = () => {

    this.setState({
      isLoading: true,
    });

    // load data;
    seatableMarketInternalAPI.listVideos().then(res => {
      let videoList = res.data.video_list.map(video => {
        return new Video(video);
      });

      let { videoCategories } = this.state;
      videoList.forEach(video => {
        if (videoCategories.indexOf(video.category) === -1) {
          videoCategories.push(video.category);
        }
      });
      let videoMap = this.calculateVideoMap(videoList, videoCategories);

      this.setState({
        isLoading: false,
        isVideoLoaded: true,
        isExistVideo: videoList.length > 0 ? true : false,
        videoMap: videoMap,
        videoCategories: videoCategories,
      });
    }).catch(error => {
      let errMessage = Utils.getErrorMsg(error);
      toaster.danger(errMessage);
      this.setState({isLoading: false});
    });

  }

  calculateVideoMap = (videoList, videoCategories) => {
    let videoMap = {};
    videoCategories.forEach(category => {
      videoMap[category] = videoList.filter(video => {
        return video.category === category;
      });
    });
    return videoMap;
  }


  renderCategoryVideos = (category) => {
    let { videoMap, searchParams } = this.state;
    return (
      <div className="template-category mb-7" key={category}>
        <h3 className="template-category-name">{category}</h3>
        <VideoList videoList={videoMap[category]} searchParams={searchParams} />
      </div>
    );
  }

  render() {
    let { isExistVideo, videoCategories, category, isLoading } = this.state;

    if (isLoading) return <Loading />;
    if (!isExistVideo) {
      return <div className="template-module">
        <EmptyTip>
          <p>{gettext('There are no videos in the config table, please build and register.')}</p>
        </EmptyTip>
      </div>;
    }

    return (
      <div className="template-module px-4">
        <MediaQuery query="(max-width: 767.8px)">
          <ul className="template-categories video-categories d-flex flex-wrap align-items-center list-unstyled p-2 my-4">
            {videoCategories.map(categoryName => {
              return <li key={categoryName} className={`template-category-item p-2 user-select-none ${categoryName === category ? 'active' : ''}`} onClick={this.onCategoryClick.bind(this, categoryName)}>{categoryName}</li>;
            })}
          </ul>
        </MediaQuery>
        <MediaQuery query="(min-width: 767.8px)">
          <ul className="template-categories video-categories d-flex flex-wrap align-items-center list-unstyled p-4 mt-6 mb-7">
            {videoCategories.map(categoryName => {
              return <li key={categoryName} className={`template-category-item px-4 py-1 user-select-none ${categoryName === category ? 'active' : ''}`} onClick={this.onCategoryClick.bind(this, categoryName)}>{categoryName}</li>;
            })}
          </ul>
        </MediaQuery>
        {category !== ALL && (this.renderCategoryVideos(category))}
        {category === ALL && (
          videoCategories.map(category => {
            if (category === ALL) {
              return '';
            }
            return this.renderCategoryVideos(category);
          })
        )}
      </div>
    );
  }
}

export default VideoModule;
