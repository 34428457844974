import React from 'react';
import PropTypes from 'prop-types';
import VideoItem from './video-item';

const propTypes = {
  videoList: PropTypes.array,
  searchParams: PropTypes.object,
};

class VideoList extends React.Component {

  render() {
    let { videoList } = this.props;

    return (
      <div className="template-list-container video-list-container mt-1">
        {videoList.map((video, index) => {
          return (<VideoItem key={index} video={video} searchParams={this.props.searchParams} />);
        })}
      </div>
    );
  }
}

VideoList.propTypes = propTypes;

export default VideoList;
