import React from 'react';
import PropTypes from 'prop-types';
import SeatableCard from '../seatable-card';

const propTypes = {
  plugin: PropTypes.object.isRequired,
  searchParams: PropTypes.object
};

class PluginItem extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    let { plugin } = this.props;
    return (<SeatableCard type={'plugin'} card={plugin} searchParams={this.props.searchParams} />);
  }
}

PluginItem.propTypes = propTypes;

export default PluginItem;
