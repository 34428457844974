import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import CardDetailDialog from './dialog/card-detail-dialog';
import { Utils } from '../utils/utils';
import '../css/seatable-card.css';

const propTypes = {
  type: PropTypes.string.isRequired,
  card: PropTypes.object.isRequired,
  searchParams: PropTypes.object,
};

class SeaTableCard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isDetailDialogShow: false,
    };
  }

  componentDidMount() {
    const { searchParams } = this.props;
    if (searchParams && searchParams.name === this.props.card.name) {
      this.setState({isDetailDialogShow: true});
    }
  }

  onDetailDialogToggle = () => {
    const { card } = this.props;
    let { isDetailDialogShow } = this.state;
    if (isDetailDialogShow) {
      Utils.changeSearch('name', null);
    } else {
      Utils.changeSearch('name', card.name);
    }
    this.setState({isDetailDialogShow: !this.state.isDetailDialogShow});
  }

  render() {

    let { type, card } = this.props;
    let { isDetailDialogShow } = this.state;
    const posterUrl = card.card_image_expanded_url;

    const videoJsOptionsPC = {
      width: (window.innerWidth - 520),
      height: 'auto',
      autoplay: false,
      controls: true,
      preload: 'auto',
      sources: [{
        src: card.link,
        type: 'video/mp4',
      }],
      poster: posterUrl
    };

    const videoJsOptionsMobile = {
      width: window.innerWidth > window.innerHeight ? (window.innerWidth - 85) : (window.innerWidth - 50),
      height: 'auto',
      autoplay: false,
      controls: true,
      preload: 'auto',
      sources: [{
        src: card.link,
        type: 'video/mp4',
      }],
      poster: posterUrl
    };
    return (
      <Fragment>
        <div className="card-container" onClick={this.onDetailDialogToggle}>
          <div className="card-image">
            <img src={card.card_image_url} alt={card.display_name} />
          </div>
          <div className="card-info">
            <h2 className="card-name">{card.display_name}</h2>
            <p className="card-description text-truncate">{card.description}</p>
          </div>
        </div>
        {isDetailDialogShow && (
          <Fragment>
            <MediaQuery query="(max-width: 767.8px)">
              <CardDetailDialog
                type={type}
                card={card}
                toggle={this.onDetailDialogToggle}
                options={videoJsOptionsMobile}
              />
            </MediaQuery>
            <MediaQuery query="(min-width: 767.8px)">
              <CardDetailDialog
                type={type}
                card={card}
                toggle={this.onDetailDialogToggle}
                options={videoJsOptionsPC}
              />
            </MediaQuery>
          </Fragment>

        )}
      </Fragment>

    );
  }
}

SeaTableCard.propTypes = propTypes;

export default SeaTableCard;
