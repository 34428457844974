import React from 'react';
import PropTypes from 'prop-types';
import SeatableCard from '../seatable-card';

const propTypes = {
  video: PropTypes.object.isRequired,
  searchParams: PropTypes.object,
};

class VideoItem extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    let { video } = this.props;
    return (<SeatableCard type={'video'} card={video} searchParams={this.props.searchParams} />);
  }
}

VideoItem.propTypes = propTypes;

export default VideoItem;
