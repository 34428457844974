import React from 'react';
import ReactDOM from 'react-dom';
import HeaderPanel from './components/header-panel';
import MainPanel from './components/main-panel';

import './css/market-layout.css';

class AppSeatableMarket extends React.Component {

  render() {

    return (
      <div id="main">
        <HeaderPanel></HeaderPanel>
        <MainPanel></MainPanel>
      </div>
    );
  }
}

ReactDOM.render(
  <AppSeatableMarket />,
  document.getElementById('wrapper')
);
